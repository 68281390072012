<template>
  <div class="csn-nup">
    <ul :class="accountMenuClass">
      <template v-if="isLogged">
        <li v-if="rendersAccountBalance" class="casino-account-balance">
          <NavbarAccountBalance />
        </li>
        <Route
          v-if="isPanelRoute"
          :to="{ name: route.game }"
          class="csn-nup-close-btn"
        >
          <CloseIcon />
        </Route>
        <li v-if="rendersNotifications">
          <UserNotifications />
        </li>
        <li v-if="!isMobileScreen">
          <Route :to="{ name: route.dashboard }">
            <ClubStatusIcon />
          </Route>
        </li>
        <li v-if="!isMobileScreen">
          <UserMenu />
        </li>
        <li v-if="!isMobileScreen">
          <NavbarSearchButton />
        </li>
      </template>

      <template v-else>
        <li>
          <LanguageSelect />
        </li>
        <li>
          <NavbarLoginButton />
        </li>
        <li>
          <NavbarRegisterButton />
        </li>
        <li>
          <NavbarSearchButton />
        </li>
        <li>
          <NavbarPayAndPlayButton />
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import {
  RouteName,
  NAVBAR__USER_PANEL,
  Module,
  IS_LOGGED,
  IS_MOBILE_SCREEN,
  CURRENT_LOCATION,
} from '@/constants'
import { dest } from '@/helpers'

const ACCOUNT_MENU = 'account-menu'
const CSN_ACCOUNT_MENU = `csn-${ACCOUNT_MENU}`
const LOGIN_IN = 'login-in'
const NO_LOGIN = 'no-login'

export default {
  name: NAVBAR__USER_PANEL,
  components: {
    NavbarLoginButton: () => import('./NavbarLoginButton'),
    UserMenu: () => import('./UserMenu'),
    UserNotifications: () => import('@/components/UserNotifications'),
    LanguageSelect: () => import('./LanguageSelect'),
    Route: () => import('@/components/Route'),
    NavbarSearchButton: () => import('./NavbarSearchButton'),
    ClubStatusIcon: () => import('@/components/ClubStatusIcon'),
    NavbarRegisterButton: () => import('./NavbarRegisterButton'),
    NavbarAccountBalance: () => import('./NavbarAccountBalance'),
    NavbarPayAndPlayButton: () => import('./NavbarPayAndPlayButton'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
  },
  computed: {
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    ...mapState(Module.LOCATION, [CURRENT_LOCATION]),
    accountMenuClass() {
      return [
        ACCOUNT_MENU,
        CSN_ACCOUNT_MENU,
        this.isLogged ? LOGIN_IN : NO_LOGIN,
      ]
    },
    route: () => ({
      dashboard: RouteName.DASHBOARD,
      registration: RouteName.REGISTRATION,
      game: RouteName.GAME,
    }),
    rendersAccountBalance() {
      return /*!this.isPanelRoute &&*/ !this.isMobileScreen
    },
    isPanelRoute() {
      return (
        this.CURRENT_LOCATION.name === RouteName.DEPOSIT ||
        this.CURRENT_LOCATION.name === RouteName.WITHDRAWAL ||
        this.CURRENT_LOCATION.name === RouteName.WITHDRAWAL_ADD
      )
    },
    rendersNotifications() {
      return !this.isMobileScreen || (this.isMobileScreen && !this.isPanelRoute)
    },
  },
}
</script>
